import { nanoid } from 'nanoid';

// ======================= HEAD DATA =======================
export const headData = {
  title: 'Constantin | 👨‍💻', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my code-fu world.', // e.g: Welcome to my website
};

// ======================= HERO DATA =======================
export const heroData = {
  title: 'Hey, my name is',
  name: 'Constantin',
  subtitle: 'I write code.',
  cta: 'Know more',
};

// ======================= ABOUT DATA =======================
export const aboutData = {
  img: 'profile-2.png',
  paragraphOne:
    "I'm a highly motivated web developer with a passion for problem solving by writing clean, high quality code that humans can understand. Besides, I am a software engineering student at CODE Berlin.",
  paragraphTwo: 'My skill set includes:',
  paragraphThree:
    'Front End: JavaScript, React, Redux, React Native, HTML, CSS. Back End: Node.js, PostgreSQL.',
  paragraphFour: 'When I am not writing code, I like to read philosophy books or play jazz guitar.',
  resume: 'https://drive.google.com/file/d/1rLxD8bs5KNF4WZF871naXRc3Ek_qLQoW/view?usp=sharing', // if no resume, the button will not show up
};

// ======================= PROJECTS DATA =======================
export const projectsData = [
  {
    id: nanoid(),
    img: 'brand-sound-explorer.png',
    title: 'Brand Sound Explorer for WESOUND GmbH',
    info:
      'A brand sound mixer that allows customers to explore the sound produced for their brand. The sound track is divided into 4 instrumental parts (Drums, Brass, String, Synth/FX) and 4 content parts. All parts can be soloed, muted or looped. In addition, the master volume and the volume of the individual parts can be controlled. If you are satisfied with the mixed result, you can download it with the chosen settings: your new, customized brand sound is ready.',
    info2:
      'Built with React, Redux, Node.js, Express, custom CSS and wavesurfer.js API. I can just share a video of the apps functionality, because the content and the source code belongs to WESOUND GmbH.',
    url: 'https://drive.google.com/file/d/1UeBv5q41E3glmV5fm-6de9JPEZjwg5Ix/view?usp=sharing',
  },
  {
    id: nanoid(),
    img: 'readable.png',
    title: 'Readable',
    info: 'Readable is a fully responsive content and comment web app.',
    info2:
      'The app is built with React and Redux and styled with Tailwind.css. The API is built with Axios. The backend server is built with Node/Express.',
    url: 'https://readable-tech-forum.netlify.app/',
    repo: 'https://github.com/conuko/Readable-Frontend', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'would-you-rather-app.png',
    title: 'Would You Rather',
    info:
      'Lets a user play the Would You Rather? game. The game goes like this: A user is asked a question in the form: Would you rather [option A] or [option B]?',
    info2:
      "Built with React, Redux, Material-UI and styled-components as my final assessment project for Udacity's React Redux course.",
    url: 'https://what-would-you-rather.netlify.app/',
    repo: 'https://github.com/conuko/would-you-rather', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'mobile-flashcards.png',
    title: 'Mobile Flashcards',
    info:
      'A mobile App for Android and iOS that allows users to study and play collections of flashcards.',
    info2:
      'Built with React-Native, Redux, Expo, React-Native-Paper, Styled-Components and AsyncStorage to simulate API calls.',
    url: 'https://github.com/conuko/mobile-flashcards',
    repo: 'https://github.com/conuko/mobile-flashcards', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'smartBrain.png',
    title: 'smartBrain: a Face Recognition App',
    info:
      "Using Clarifai's AI, this fully responsive app will detect faces in given picture, increasing your personal score. Just sign in, paste a URL and and start detecting!",
    info2: 'Built with React.js, Node.js, PostgreSQL, Tachyons',
    url: 'https://face-brain-recognition.herokuapp.com/',
    repo: 'https://github.com/conuko/face-recognition-brain', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'my-reads.png',
    title: 'My Reads: A Book Tracking App',
    info:
      'My Reads is a React application that allows you to select and categorize books you have read, are currently reading, or want to read.',
    info2:
      "I created the My Reads application as my final assessment project for Udacity's React Fundamentals course. Created with React and React-Router. ",
    url: 'https://myreads-a.netlify.app/',
    repo: 'https://github.com/conuko/MyReads', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'robofriends.png',
    title: 'Robofriends',
    info:
      'A fully responsive simple React App using stateful components and lifecycle hooks to look up users, using a fake API.',
    info2: 'Built with React.js, Tachyons.',
    url: 'https://conuko.github.io/robofriends-with-hooks/',
    repo: 'https://github.com/conuko/robofriends-with-hooks', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'todos-goals.png',
    title: 'My Goals and Todos',
    info:
      'A small web application that allows you to add, toggle and delete todos and goals. The app is connected to a fake-API which randomly displays an error message now and then. This is intentional and only serves to illustrate how React and Redux can handle error messages from an API.',
    info2:
      'My Goals and Todos was built with React.js, Redux, Redux Middleware, react-redux and tailwindcss.',
    url: 'https://conuko.github.io/redux-todos-goals/',
    repo: 'https://github.com/conuko/redux-todos-goals', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'pixel-art-maker.png',
    title: 'Pixel Art Maker',
    info: 'Single-page web app that allows users to draw pixel art on a customizable canvas.',
    info2: 'Created with Vanilla JS and HTML Tables.',
    url: 'https://conuko.github.io/pixel-art-maker/',
    repo: 'https://github.com/conuko/pixel-art-maker', // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: 'project.jpg',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // },
];

// ======================= CONTACT DATA =======================
export const contactData = {
  cta: '',
  btn: '',
  email: 'mail@constantincodes.dev',
};

// ======================= FOOTER DATA =======================
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/constantin-unterkofler',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/conuko',
    },
  ],
};

// =======================Github start/fork buttons =======================
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
